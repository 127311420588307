.searchPaneMain {
    width: 50%;
    margin-left: 10%;
}

.searchPane {
    width: 50%;
    margin: auto;
    margin-bottom: 1.5rem;
}

.tripTypeButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
}

.tripTypeButtons button {
    width: 10rem;
    height: 2.5rem;
    border-radius: 10px;
    padding: 0 0.25rem;
    margin: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.buttonActive {
    opacity: 1;
}
.buttonInactive {
    opacity: 0.7;
}