.list {
    list-style: none;
    margin: auto;
    padding: 0;
    display: block;
    align-items: baseline;
    align-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
}

.direction-date {
    justify-content: space-between;
    display: flex;
}

.direction-date h2 {
    display: flex;
    float: left;
}

.direction-date h3 {
    display: flex;
    float: right;
    color: grey;
}

.imageButton {
    width: 3rem;
    height: 3rem;
    border-radius: 0.25rem;
    border: 1px solid black;
    padding: 0.5rem;
    resize: both;
}

.pagingButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.pagingButtons label {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
