footer {
    background-color: #d1b096;
    text-align: center;
    padding: 1rem 0 0.5rem 0;
    font-size: smaller;
    font-weight: lighter;
    z-index: 1;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-container a {
    text-decoration: none;
    color: black;
    opacity: 0.7;
}

.footer-container a:hover {
    opacity: 1.0;
}

.footer-container hr {
    width: 70vw;
    opacity: 0.1;
}

.footer-copyright {
    font-size: smaller;
    opacity: 0.7;
}

.footer-icon {
    font-size: 1rem;
    text-decoration: none;
    opacity: 0.7;
    color: black;
    padding: 0.2rem;
}

.footer-spacer {
    height: 1rem;
}

.footer-social-icon {
    font-size: 2rem;
    text-decoration: none;
    color: black;
    padding: 0.3rem;
}
