

.form-container {
    border-radius: 1rem;
    border: 3px solid black;
}

.form {
    border-radius: 1rem;
    border-color: black;
    padding: 1rem 1rem 1rem 1rem;
    background-color: white;
}

.form label {
    font-weight: bold;
    width: 20%;
    margin: 0.5rem 0 0.5rem 0;
    text-align: left;
}

.passengers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.passengers label{
    font-weight: bold;
    width: 10%;
    min-width: 100px;
}

.passengers .input[type="number"] {
    width: 5%;
}

.date-block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}


.date-picker-wrapper {
    margin: 0.5rem 0 0.5rem 0;
    padding: 0 1rem 0 1rem;
}

.date-block label {
    width: 20%;
    min-width: 100px;
    margin: 0.5rem 0 0.5rem 0;
}


.destination-select {
    margin: 0.5rem 0 0.5rem 0;
    padding: 0 1rem 0 0;
    border: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.destination-select div {
    display: inline block;
}

.buttonDiv {
    display: flex;
    justify-content: right;
}


