.navigation {
    height: 6rem;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    position: sticky;
    top: 0;
    background-color: #192A30;
    padding: 1rem;
    z-index: 100;
}

.navigation-logo {
    font-family: 'Bebas Neue', cursive;
    text-decoration: none;
    color: #ffffff;
    font-size: 2.5rem;  
}

.logo {
    height: 3.5rem;
}

.navigation-logo:hover {
    color: #ffffff;
}

.navigation-menu {
    margin-left: auto;
}

.navigation-menu ul {
    display: flex;
    margin: 0;
    padding: 0;
}

.navigation-menu li {
    list-style-type: none;
    padding: 1.5rem;
}

.navigation-menu li a {
    color: #ffffff;
    text-decoration: none;
}

.navigation-menu li a:hover {
    color: #E6CBA8;
    text-decoration: none;
}

.navigation-menu-button {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: none;
    color: #ffffff;
    font-size: 0.8rem;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.navigation-menu-button:hover {
    background-color: #E6CBA8;
}

.navigation-menu-icon {
    font-size: large;
}

@media screen and (max-width: 768px) {
    .navigation-menu-button {
        position: absolute;
        display: block;
        right: 0;
    }

    .navigation-menu.expanded ul {
        display: block;
        padding: 0;
    }

    .navigation-menu ul {
        display: none;
    }

    .navigation-menu ul {
        position: absolute;
        top: 6rem;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 6rem);
        background-color: white;
    }

    .navigation-menu li {
        text-align: center;
        padding: 1.5rem;
        list-style-position: outside;
        border-bottom: 1px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to left, transparent, #E6CBA8, transparent);
    }

    .navigation-menu li a {
        color: black;
        opacity: 0.7;
    }

    .navigation-menu li a:hover {
        color: white;
    }

    .navigation-menu li:hover {
        background-color: #E6CBA8;
    }
}