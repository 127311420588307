.payment-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.payment-error-container {
    color: black;
    background-color: white;
    border-radius: 10px;
    padding: 4rem;
    width: 80vw;
    text-align: center;
}

.payment-error-icon {
    font-size: 4rem;
    color: #D32F2F;
    padding: 1rem;
}

.payment-error-spacer {
    height: 2rem;
}

h1 {
    padding: 1rem;
    opacity: 0.8;
    color: black;
}

span {
    opacity: 0.7;
    color: black;
}

.payment-error-span {
    display: block;  /* Ensure it's displayed as a block element */
    font-size: medium;
    opacity: 0.8;
    color: black;
    margin-bottom: 20px;  /* Add some space below the booking ID */
}

.cancel-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.cancel-booking-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #dc3545;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.cancel-booking-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
  
.cancel-success-message,
.cancel-error-message {
    margin-top: 20px;
    font-size: 16px;
}
  
.cancel-success-message {
    color: green;
}
  
.cancel-error-message {
    color: red;
}
