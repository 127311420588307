.confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.confirmation-container {
    color: black;
    background-color: white;
    border-radius: 10px;
    padding: 4rem;
    width: 80vw;
    text-align: center;
}

.confirmation-icon {
    font-size: 4rem;
    color: #388E3C;
    padding: 1rem;
}

.confirmation-spacer {
    height: 2rem;
}

h1 {
    padding: 1rem;
    opacity: 0.8;
    color: black;
}

span {
    opacity: 0.7;
    color: black;
}

.confirmation-booking-span {
    font-size: medium;
    opacity: 0.8;
    color: black;
}