.main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Roboto", sans-serif;
    background-color: white;
}


button.btn.btn-primary {
    background-color: #D1B096;
    color: black;
    border: none;
    transition: all 0.3s ease;
    box-shadow: 0 0 5px 0 hsl(0, 0%, 74%);
}

button.btn.btn-primary:hover {
    color: black;
    background-color: #D1B096;
    box-shadow: 0 0 10px 0 hsl(0, 0%, 74%);
}

div.form-wrapper {
    height: auto;
    width: 80vh;
    display: block;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    padding: 1.25rem;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    div.form-wrapper {
        padding: 10px;
        width: 100%;
    }

}

.form-control {
    border: 1px solid hsl(0, 0%, 74%);
    border-radius: 5px;
    box-shadow: none;
    transition: all 0.3s ease;
}

.form-control:focus {
    box-shadow: 0 0 10px 0 hsl(0, 0%, 74%);
    border: none;
}

div.form-floating > .form-control {
    height: calc(3.5rem + 5px)
}